.background_container {
    /*width: 100vw;*/
    height: 100vh;
    object-fit: cover;
    background-image: url("../../../public/assets/LandingPageBase.jpg");
}

.landing_page_nav_bar {
    /*height: 10rem;*/
    display: flex;
    flex-direction: row;
    width: 100%;
    position: absolute;
    justify-content: space-between;

}

.landing_page_company_text {
    color: #FFFFFF;
    display: inline-block;
    font-size: 10rem;
    /*height: 100%;*/
    font-family: "Roboto Mono", Helvetica, sans-serif;
    user-select: none;
}

p {
    user-select: none;
}

.your_digital_homepage {
    display: flex;
    font-size: 2rem;
    align-self: flex-end;
    font-family: "Roboto Mono", Helvetica, sans-serif;
    user-select: none;
    color: #FFFFFF;

}

.landing_page_hero_text_container {
    position: absolute;
    top: 60%;
    left: 23%;
    transform: translate(-50%, -50%);
    padding: 10px;
    border-radius: 5px;
}


@font-face {
    font-family: 'Roboto Mono';
    src: local('Roboto Mono'), url(../../../public/fonts/Roboto_Mono/static/RobotoMono-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Ponnala';
    src: local('Roboto Mono'), url(../../../public/fonts/Ponnala/Ponnala-Regular.ttf) format('truetype');
}

.landing_page_hero_text {
    color: #FFFFFF;
    font-size: 4rem;
    font-family: "Roboto Mono", Helvetica, sans-serif;
    margin: 2rem;
}


.something {
    display: flex;
}

.signin_button_container {
    display: flex;
    align-items: center;
    margin: 1rem;
}

.landing_page_button {
    width: 10rem;
    height: 4rem;
}

.landing_page_monkey_image_container {
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    overflow: hidden;

}

.landing_page_fairy_image {
    position: relative;
    /*top: 23rem;*/
    /*left: 24rem;*/
    width: 80rem;
}


.shine {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;
    z-index: 2;
    color: tansparent;
    -moz-opacity: 0.0;
    opacity: 0.0;
    animation: glitter 6s linear 0s infinite normal;
    -webkit-animation: glitter 6s linear 0s infinite normal;
    -moz-animation: glitter 8s linear 0s infinite normal;
    -ms-animation: glitter 8s linear 0s infinite normal;
    -o-animation: glitter 8s linear 0s infinite normal;
}

.shine.small {
    width: 20px;
    height: 20px;
}

.shine.medium {
    width: 30px;
    height: 30px;
}

.shine.large {
    width: 50px;
    height: 50px;
}

/*CSS3 keyframes for glittering effect*/
@-webkit-keyframes glitter {
    0% {
        -webkit-transform: scale(0.3) rotate(0deg);
        opacity: 0;
    }
    25% {
        -webkit-transform: scale(1) rotate(360deg);
        opacity: 1;
    }
    50% {
        -webkit-transform: scale(0.3) rotate(720deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: scale(0.3) rotate(0deg);
        opacity: 0;
    }
}